import Vue from 'vue';
import App from './App.vue';
import router from './router/app.router';
import vuetify from './plugins/vuetify';
// import axios from 'axios';
import { axiosInstance } from '@/services/base';

import './plugins/vue-wysiwyg';
import './plugins/view-design';
import './assets/styles/style.scss';

import '@/mixins/base';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';


/*global process*/
/*eslint no-undef: "error"*/

Bugsnag.start({
    apiKey: 'adb9badeaddfbe77fb860ca6796e3d3c',
    releaseStage: process.env.VUE_APP_ENV,
    plugins: [new BugsnagPluginVue()],
});

const bugsnagVue = Bugsnag.getPlugin('vue');
bugsnagVue.installVueErrorHandler(Vue);

Vue.config.productionTip = false;
Vue.prototype.$http = axiosInstance;
// axiosInstance.defaults.timeout = 10000;

router.beforeEach((to, from, next) => {
    if (!to.matched.length) {
        next('/404-page-not-found');
    } else {
        const userRole = window.localStorage.getItem('mainRole');
        if (to.meta.requiredAuth) {
            if (window.localStorage.getItem('is_authenticated')) {
                if (to.meta.roles.includes(userRole)) {
                    next();
                } else {
                    if (userRole === 'dev' || userRole === 'pm') {
                        router.push({ name: 'UserDashboard' }).catch(() => {});
                    }
                    if (userRole === 'admin') {
                        router.push({ name: 'Projects' }).catch(() => {});
                    }
                    if (userRole === 'client') {
                        router.push({ name: 'Client' }).catch(() => {});
                    }
                }
            } else {
                next('/login');
            }
        } else {
            next();
        }
    }
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('auth_token');
        if (token) {
            config.headers.common['Authorization'] = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },

    (error) => {
        try {
            if (error.response.status) {
                switch (error.response.status) {
                    case 401:
                        try {
                            router
                                .replace({
                                    path: '/login',
                                })
                                .catch(() => {});
                        } catch (e) {
                            Bugsnag.notify(e);
                        }
                        break;
                    case 502:
                        try {
                            setTimeout(() => {
                                router
                                    .replace({
                                        path: '/login',
                                    })
                                    .catch(() => {});
                            }, 1000);
                        } catch (e) {
                            Bugsnag.notify(e);
                        }
                }
                return Promise.reject(error.response);
            }
        } catch (e) {
            Bugsnag.notify(e);
        }
    }
);

new Vue({
    vuetify,
    router,
    render: (h) => h(App),
}).$mount('#app');
