<template>
    <Alert type="error">
        <ul v-if="errors.length > 1" class="md-margin-left">
            <li>test</li>
            <li>test</li>
        </ul>
        <p v-else>
            {{ errors[0] }}
        </p>
    </Alert>
</template>
<script>
export default {
    props: {
        errors: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
