import Vue from 'vue';

Vue.mixin({
    data() {
        return {
            projectTypes: [
                {
                    name: 'Fix-terms',
                    value: 'FT',
                },
                {
                    name: 'Startup Oasis',
                    value: 'SO',
                },
                {
                    name: 'Retainer',
                    value: 'RT',
                },
                {
                    name: 'Retainer with PM',
                    value: 'RPm',
                },
            ],
            mainRole: [
                {
                    name: 'Admin',
                    value: 'admin',
                },
                {
                    name: 'Client',
                    value: 'client',
                },
                {
                    name: 'Developer',
                    value: 'dev',
                },
                {
                    name: 'Project Manager',
                    value: 'pm',
                },
            ],
            projectRole: [
                {
                    name: 'Admin',
                    value: 'admin',
                },
                {
                    name: 'Client',
                    value: 'client',
                },
                {
                    name: 'Developer',
                    value: 'dev',
                },
                {
                    name: 'Project Director',
                    value: 'pd',
                },
                {
                    name: 'Project Manager',
                    value: 'pm',
                },
                {
                    name: 'UI/UX Designer',
                    value: 'uiux',
                },
            ],
            filterStatus: [
                {
                    name: 'Regular',
                    value: 'regular',
                },
                {
                    name: 'On Leave',
                    value: 'leave',
                },
                {
                    name: 'Undertime',
                    value: 'undertime',
                },
                {
                    name: 'Absent',
                    value: 'absent',
                },
                {
                    name: 'Late',
                    value: 'late',
                },
                {
                    name: 'Special Working Holiday',
                    value: 'sworkholiday',
                },
                {
                    name: 'Special Non-working Holiday',
                    value: 'snonworkholiday',
                },
                {
                    name: 'Regular Holiday',
                    value: 'regholiday',
                },
                {
                    name: 'Rest Day',
                    value: 'rest',
                },
                {
                    name: 'Time Dispute',
                    value: 'timedispute',
                },
            ],
            requestTypes: [
                {
                    name: 'Time Disputes',
                    value: 'timedispute',
                },
                {
                    name: 'Project Log dispute',
                    value: 'projectlogdispute',
                },
                {
                    name: 'Leave Requests',
                    value: 'leave',
                },
                {
                    name: 'Loan Requests',
                    value: 'loan',
                },
                {
                    name: 'COE Requests',
                    value: 'coerequest',
                },
            ],
            requestStatus: [
                {
                    name: 'Pending',
                    value: 'pending',
                },
                {
                    name: 'Approved',
                    value: 'approved',
                },
                {
                    name: 'Rejected',
                    value: 'rejected',
                },
            ],
        };
    },
    methods: {},
});
