import { axiosInstance } from './base';

export function getUsers() {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`users`, { headers });
}

export function getAllArchivedUsers() {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`users/get-all-archived-users`, { headers });
}

export function getUsersById(user_id) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`users/${user_id}`, { headers });
}

export function postUsers(data) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.post(`users`, data, { headers });
}

export function updateUsersById(user_id, data) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.patch(`users/${user_id}`, data, { headers });
}

export function archiveUser(user_id, data) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.patch(`users/archive/${user_id}`, data, { headers });
}

export function userChangePassword(user_id, data) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.post(`users/${user_id}/change-password`, data, {
        headers,
    });
}
