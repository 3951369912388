<template>
    <div class="login-page-wrapper fullheight">
        <div class="head-text">
            <h2 style="color: #fff; font-weight: 400">WELCOME TO THE STARTECHUP</h2>
            <h1 style="margin-bottom: 30px; color: #fff; font-size: 60px">PROJECT TRACKER</h1>
        </div>
        <!-- <img src="@/assets/STU Logo.png" class="login-stu-logo" /> -->
        <Card dis-hover :bordered="false" style="width: 500px" class="login-form-wrapper">
            <div class="img-header2"><img src="~@/assets/images/STU PT headers login@2x.png" height="100px;" /></div>
            <Form ref="loginForm" :model="formData" :rules="formRules">
                <FormItem prop="email">
                    <input
                        v-model="formData.email"
                        class="inputStyle"
                        type="text"
                        placeholder="Email address"
                        size="large"
                    />
                </FormItem>
                <FormItem prop="password">
                    <input
                        v-model="formData.password"
                        class="inputStyle"
                        type="password"
                        placeholder="Password"
                        size="large"
                        @keyup.enter="handleLogin()"
                    />
                    <div class="text-right">
                        <Button type="text" class="no-padding" @click="$refs.ForgotPasswordModal.openModal()">
                            Forgot password?
                        </Button>
                    </div>
                </FormItem>
                <FormItem>
                    <Button class="pt-btn-3 login-btn" long size="large" :loading="isLoading" @click="handleLogin">
                        SIGN IN
                    </Button>
                </FormItem>
            </Form>
            <ForgotPasswordModal ref="ForgotPasswordModal" />
        </Card>
        <p class="stu-desc">STU Project Tracker version 3.0 Made by Startechup Inc. &copy; 2022 All Rights Reserved</p>
    </div>
</template>

<script>
import { login, setAuthSession } from '../services/auth.services';
import { getBitbucketToken } from '@/services/bitbuckets.services';
import { getUsersById } from '../services/users.services.js';
import ForgotPasswordModal from '@/components/Auth/ForgotPassword';
import Bugsnag from '@bugsnag/js';

export default {
    // name: 'Login',
    components: {
        ForgotPasswordModal,
    },
    data: () => ({
        showPassword: false,
        formData: {
            email: '',
            password: '',
        },
        formRules: {
            email: [
                {
                    required: true,
                    message: 'Please fill in the email',
                    trigger: 'blur',
                },
                { type: 'email', message: 'Incorrect email format', trigger: 'blur' },
            ],
            password: [
                {
                    required: true,
                    message: 'Please fill in the password.',
                    trigger: 'blur',
                },
            ],
        },
        isLoading: false,
    }),
    methods: {
        async handleLogin() {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.postLogin();
                } else {
                    this.$Message.error('Fill in the required fields');
                }
            });
        },

        postLogin() {
            this.isLoading = true;
            login(this.formData)
                .then((res) => {
                    if (res.data) {
                        setAuthSession(res.data);
                        this.handleGetBitbucketToken();
                        getUsersById(res.data.user_id).then((res) => {
                            if (res.data) {
                                localStorage.setItem('mainRole', res.data.mainRole);
                                localStorage.setItem('trelloId', res.data.trelloId);
                                localStorage.setItem('jiraId', res.data.jiraId);
                                if (res.data.mainRole === 'dev' || res.data.mainRole === 'pm') {
                                    this.$router.push({ name: 'UserDashboard' }).catch(() => {});
                                }
                                if (res.data.mainRole === 'admin') {
                                    this.$router.push({ name: 'Projects' }).catch(() => {});
                                }
                                if (res.data.mainRole === 'client') {
                                    this.$router.push({ name: 'Client' }).catch(() => {});
                                }
                            }
                        });
                        this.$Message.success('Successfully logged in');
                        this.isLoading = true;
                    }
                })
                .catch((err) => {
                    try {
                        if (err.data.statusCode === 401) {
                            this.$Message.error('The email or password you entered is incorrect.');
                        } else if (err.data.statusCode === 404) {
                            this.$Message.error('We could not find an account for that email address.');
                        } else {
                            this.$Message.error('Failed to login');
                        }
                    } catch (e) {
                        Bugsnag.notify(e);
                    }

                    this.isLoading = false;
                });
        },

        handleGetBitbucketToken() {
            getBitbucketToken()
                .then((res) => {
                    if (res.data) {
                        localStorage.setItem('bitbucket_token', res.data.access_token);
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get bitbucket token');
                });
        },
    },
};
</script>

<style lang="scss">
.login-page-wrapper {
    background: url('~@/assets/images/STU Tracker Login.svg');
    background-repeat: no-repeat;
    background-size: contain;
    // background-size: cover;
    // background-position: center;

    .head-text {
        position: absolute;
        top: 25%;
        // transform: translateX(-55%);
        left: 2%;
    }

    .stu-desc {
        position: absolute;
        background: transparent;
        bottom: 10px;
        right: 25px;
        color: #a7a7a7;
        font-size: 14px;
    }

    .login-stu-logo {
        position: absolute;
        top: 10%;
        left: 5%;
        width: 400px;
    }

    .login-form-wrapper {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 5%;
        background: transparent;

        .img-header2 {
            justify-content: center;
            display: flex;
            margin-bottom: 30px;
        }

        .ivu-form-item {
            margin-bottom: 45px;
        }

        .ivu-form-item-content {
            display: contents;
        }

        .ivu-input {
            background-color: transparent;
            border: 2px solid $primary-color;
            border-radius: 5px;

            &::placeholder {
                color: $primary-color;
                opacity: 1;
            }
        }

        .login-btn {
            &:hover {
                background-color: transparent;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .login-page-wrapper {
        background-size: 180% !important;
        background-position: 130% 0px !important;

        .head-text {
            // text-align: center;
            // left: 0 !important;
            // position: relative !important;
            display: none;

            h1 {
                font-size: 36px !important;
            }
        }

        .login-form-wrapper {
            width: 100% !important;
            top: 230px !important;
            right: 0 !important;

            .img-header2 {
                top: 0 !important;
                position: relative;
            }

            input {
                background: #fff;
            }
        }
    }
}
</style>
