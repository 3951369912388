import { axiosInstance } from './base';

export function login(data) {
    return axiosInstance.post(`authentication/login`, data);
}

export function setAuthSession(data) {
    localStorage.setItem('is_authenticated', true);
    localStorage.setItem('user_name', data.user_name);
    localStorage.setItem('user_mail', data.user_email);
    localStorage.setItem('auth_token', data.access_token);
    localStorage.setItem('user_id', data.user_id);
}

export function logout() {
    localStorage.removeItem('is_authenticated');
    localStorage.removeItem('user_name');
    localStorage.removeItem('user_mail');
    localStorage.removeItem('auth_token');
    localStorage.clear();
}

export function requestResetPassword(data) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.post(`authentication/reset-password/request`, data, {
        headers,
    });
}

export function changePassword(data) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.post(`authentication/reset-password/change-pass`, data, {
        headers,
    });
}

export function requestClientResetPassword(data) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.post(`authentication/invite-client`, data, { headers });
}
