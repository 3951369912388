import { axiosInstance, axiosBitbucketInstance } from './base';

export function getBitbucketToken() {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`bitbucket/token`, { headers });
}

export function getBitbucketRepositories(query) {
    const headers = {
        Authorization: `Bearer ${localStorage.getItem('bitbucket_token')}}`,
        Accept: 'application/json',
    };
    const headerToken = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    let pathUrl = `repositories/geomuling`;
    if (query) {
        pathUrl = `${pathUrl}?${query}`;
    }
    return axiosBitbucketInstance.get(pathUrl, { headers }, { headerToken });
}

export function getBitbucketCommits(filter, page, totalCount) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`bitbucket/get-commits`, {
        params: { filter: filter, sort: { createdAt: -1 }, limit: 10, page, totalCount },
        headers,
    });
}
