<template>
    <Modal v-model="showModal" title="Forgot Password?" footer-hide :before-close="handleReset">
        <div>
            <Form ref="forgotPasswordForm" :model="formData" :rules="formRules" label-position="top">
                <FormItem label="Email" prop="email">
                    <Input
                        v-model="formData.email"
                        placeholder="Enter your email"
                        @keyup.enter="handleForgotPasswordSend()"
                    />
                </FormItem>
                <FormItem class="text-right">
                    <Button type="primary" :loading="isLoading" @click="handleForgotPasswordSend"> Send </Button>
                </FormItem>
            </Form>
        </div>
    </Modal>
</template>

<script>
import { requestResetPassword } from '@/services/auth.services';
import Bugsnag from '@bugsnag/js';

export default {
    data() {
        return {
            showModal: false,
            isLoading: false,
            formData: {
                email: '',
            },
            formRules: {
                email: [
                    { required: true, message: 'Email cannot be empty', trigger: 'blur' },
                    { type: 'email', message: 'Incorrect email format', trigger: 'blur' },
                ],
            },
        };
    },
    methods: {
        openModal() {
            this.showModal = true;
        },
        handleForgotPasswordSend() {
            this.isLoading = true;
            requestResetPassword(this.formData)
                .then((res) => {
                    if (res.data) {
                        this.$Message.success('Successfully Send Email');
                        this.isLoading = true;
                    }
                })
                .catch((err) => {
                    this.$Message.error('Failed to Send Email');
                    try {
                        this.handleErrorResponse(err.response.data);
                    } catch (e) {
                        Bugsnag.notify(e);
                    }
                    this.isLoading = false;
                });
            this.isLoading = false;
            this.showModal = false;
            this.handleReset();
        },
        handleErrorResponse(err) {
            if (err.statusCode === 401) {
                this.errorMessages.push('The email you entered is incorrect.');
            } else if (err.statusCode === 404) {
                this.errorMessages.push('We could not find an account for that email address.');
            }
        },
        handleReset() {
            this.$refs.forgotPasswordForm.resetFields();
            this.isLoading = false;
        },
    },
};
</script>

<style></style>
