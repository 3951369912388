<template>
    <div class="login-page-wrapper fullheight">
        <div class="head-text">
            <h2 style="color: #fff; font-weight: 400">WELCOME TO THE STARTECHUP</h2>
            <h1 style="margin-bottom: 30px; color: #fff; font-size: 60px">PROJECT TRACKER</h1>
        </div>
        <!-- <img src="@/assets/STU Logo.png" class="login-stu-logo" /> -->
        <Card dis-hover :bordered="false" style="width: 500px" class="login-form-wrapper">
            <h1 style="margin-bottom: 30px; color: #463c6e" class="text-center">Reset Password</h1>
            <ErrorMessages v-if="errorMessages.length" ref="ErrorMessages" :errors="errorMessages" />
            <Form ref="resetPasswordForm" :model="formData" :rules="formRules">
                <FormItem prop="password">
                    <input
                        v-model="formData.password"
                        class="inputStyle"
                        type="password"
                        placeholder="New Password"
                        size="large"
                    />
                </FormItem>
                <FormItem prop="password">
                    <input
                        v-model="formData.confirm_password"
                        class="inputStyle"
                        type="password"
                        placeholder="Confirm Password"
                        size="large"
                        @keyup.enter="handleResetPassword()"
                    />
                </FormItem>
                <FormItem>
                    <Button
                        class="pt-btn-3 login-btn"
                        long
                        size="large"
                        :loading="isLoading"
                        @click="handleResetPassword"
                    >
                        Submit
                    </Button>
                </FormItem>
            </Form>
        </Card>
        <p class="stu-desc">STU Project Tracker version 3.0 Made by Startechup Inc. &copy; 2022 All Rights Reserved</p>
    </div>
</template>

<script>
import { changePassword } from '../services/auth.services';
import ErrorMessages from '@/components/UI/ErrorMessages';

export default {
    // name: 'Login',
    components: {
        ErrorMessages,
    },
    data: () => ({
        showPassword: false,
        errorMessages: [],
        formData: {
            id: '',
            code: '',
            password: '',
            confirm_password: '',
        },
        formRules: {
            password: [
                {
                    required: true,
                    message: 'Please fill in the password.',
                    trigger: 'blur',
                },
            ],
            confirm_password: [
                {
                    required: true,
                    message: 'Please fill in the password.',
                    trigger: 'blur',
                },
            ],
        },
        isLoading: false,
    }),
    methods: {
        async handleResetPassword() {
            this.$refs.resetPasswordForm.validate((valid) => {
                if (valid) {
                    if (this.formData.password === this.formData.confirm_password) {
                        this.resetpassword();
                    } else {
                        this.$Message.error('Password must match');
                    }
                } else {
                    this.$Message.error('Fill in the required fields');
                }
            });
        },
        resetpassword() {
            this.isLoading = true;
            this.errorMessages = [];
            this.formData = {
                id: this.$route.query.id,
                code: this.$route.query.code,
                password: this.formData.confirm_password,
            };
            changePassword(this.formData)
                .then((res) => {
                    if (res.data) {
                        this.$Message.success('Successfully change password');
                        this.isLoading = true;
                        window.location.href = '/login';
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to change password');
                    this.isLoading = false;
                });
            this.isLoading = false;
            this.handleReset();
        },
        handleReset() {
            this.$refs.formData.resetFields();
            this.isLoading = false;
        },
    },
};
</script>

<style lang="scss">
.login-page-wrapper {
    background: url('~@/assets/images/STU Tracker Login.svg');
    background-repeat: no-repeat;
    background-size: contain;
    // background-size: cover;
    // background-position: center;

    .head-text {
        position: absolute;
        top: 25%;
        // transform: translateX(-55%);
        left: 2%;
    }

    .stu-desc {
        position: absolute;
        background: transparent;
        bottom: 10px;
        right: 25px;
        color: #a7a7a7;
        font-size: 14px;
    }

    .login-stu-logo {
        position: absolute;
        top: 10%;
        left: 5%;
        width: 400px;
    }

    .login-form-wrapper {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 5%;
        background: transparent;

        .ivu-form-item {
            margin-bottom: 45px;
        }

        .ivu-input {
            background-color: transparent;
            border: 2px solid $primary-color;
            border-radius: 5px;

            &::placeholder {
                color: $primary-color;
                opacity: 1;
            }
        }

        .login-btn {
            &:hover {
                background-color: transparent;
            }
        }
    }
}
</style>
